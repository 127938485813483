export class ReadMore {
    constructor() {

    }

    init() {
        var self = this;

        Array.from(document.querySelectorAll('.more-btn span')).forEach(function (btn) {
            btn.addEventListener('click', function (e) {
                btn.parentNode.parentNode.parentNode.parentNode.parentNode.querySelectorAll('.more-area').forEach(function (area) {
                    if (!area.classList.contains('show')) {
                        area.classList.add('show');
                        area.style.maxHeight = area.querySelector('.more-area-inner').getBoundingClientRect().height + 55 + 'px';
                        btn.innerHTML = btn.getAttribute('data-less');
                    } else {
                        area.style.maxHeight = '0';
                        area.classList.remove('show');
                        btn.innerHTML = btn.getAttribute('data-more');
                    }
                });
            });
        });            
    }
}