import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin.js";
gsap.registerPlugin(ScrollToPlugin);

export class BaseFunctions {
    constructor(breakpointMedium, breakpointLarge, breakpointXLarge) {
        this.breakpointMedium = breakpointMedium;
        this.breakpointLarge = breakpointLarge;
        this.breakpointXLarge = breakpointXLarge;
        this.anchorScroll = false;
    }
    init() {
        this.scrollToAnchorInit();
        this.scrollDown();
        this.setMinHeader();
        window.addEventListener('scroll', this.setMinHeader.bind(this));

        this.headerCircleAni();
    }
    headerCircleAni() {
        if (document.querySelectorAll('.header-bg .circle').length > 0) {
            const self = this;
            const circle = document.querySelector('.header-bg .circle');
            gsap.to(circle, { duration: 0.5, opacity: 1, scale: 1, delay: 0.2, ease: "none", onComplete: self.startBubbleGreen, onCompleteParams: [circle, self]  });
            //this.startBubbleGreen(circle, this);
        }
    }
    startBubbleGreen(elem, self) {
        if (elem.elemX == undefined) {
            elem.elemX = gsap.getProperty(elem, "x");
            elem.elemY = gsap.getProperty(elem, "y");
        }
        var randomX = elem.elemX - 30 + Math.round(Math.random() * 60);
        var randomY = elem.elemY - 30 + Math.round(Math.random() * 60);
        var randomO = 0.7 + Math.round(Math.random());
        let duration = (elem.elemX - randomX) / 4;
        if (duration < 0) duration = duration * -1;
        if (duration < 1.3) duration = 1.3;
        gsap.to(elem, { x: randomX, y: randomY, opacity: randomO, duration: duration, ease: 'none', onComplete: self.startBubbleGreen, onCompleteParams: [elem, self] });
    }
    setMinHeader() {
        window.addEventListener('scroll', this.shrinkLogo.bind(this));
    }
    shrinkLogo(evt) {
        const header = document.querySelector('header');
        //let dir = this.getSCrollDirection();
        if (window.scrollY > 100) { // 160
            header.classList.add('min');
        } else {
            header.classList.remove('min');
        }

    }
    getSCrollDirection() {
        const self = this;
        let scrollPos = window.scrollY;
        let scrollDirection = '';
        scrollPos = window.scrollY;
        if (scrollPos > this.lastScrollTop) {
            scrollDirection = 'down';
        } else {
            scrollDirection = 'up';
        }
        this.lastScrollTop = scrollPos <= 0 ? 0 : scrollPos;
        return scrollDirection;
    }

    scrollDown() {
        if (document.querySelectorAll('.headerimage .arrow-down').length > 0) {
            document.querySelector('.headerimage .arrow-down').addEventListener('click', function () {
                gsap.to(window, { duration: 1.2, scrollTo: window.innerHeight, ease: "power3.out" });
            });
        }
    }
    scrollToAnchorInit() {
        
        var self = this;
        //if (document.body.classList.contains('home')) {
        Array.from(document.querySelectorAll('a[href*="#"]')).forEach(function (anchor) {
            anchor.addEventListener('click', function (e) {
                self.anchorScroll = true;
                self.scrollToAnchor(e, anchor.getAttribute('href'));
                e.preventDefault();
            });
        });
        //}
        if (window.location.hash) {
            window.addEventListener('load', this.scrollToAnchor.bind(this));
        }
    }
    scrollToAnchor(evt, anchor) {
        if (anchor == undefined) {
            anchor = window.location.hash;
        }
        var self = this;
        let a = anchor.split('#')[1];
        if (a != null && a != undefined) {
            //if (document.querySelectorAll("#" + a).length > 0) {
                let p = document.getElementById(a).getBoundingClientRect().top + window.scrollY - 100;
                gsap.to(window, { duration: 1.4, scrollTo: p, ease: "power3.out", onComplete: self.resetAnchorSCroll, onCompleteParams: [self] });
            //}
        }
    }
    resetAnchorSCroll(self) {
        self.anchorScroll = false;
    }
}