import { BaseFunctions } from "./js/basefunctions.js";
import { BurgerNavi } from "./js/burgernavi.js";
import { ReadMore } from "./js/readmore.js";

const breakpointSmall = 500;
const breakpointMedium = 768;
const breakpointLarge = 1200;
const breakpointXLarge = 1450;

document.addEventListener("DOMContentLoaded", function (event) {
    const basefunctions = new BaseFunctions(breakpointMedium, breakpointLarge, breakpointXLarge);
    basefunctions.init();

    const burgernavi = new BurgerNavi();
    burgernavi.init();
    if (document.querySelectorAll('.more-btn').length > 0) {
        const readmore = new ReadMore();
        readmore.init();
    }
});